import { IconSearch } from "@tabler/icons-react";
import React, { useState } from "react";

import Select from "react-select";

export default function FilterPanel({
  initial_search,
  country,
  onFilteration,
  universities,
  getUniversities,
  handleType,
  onCountryChange,
  types,
  inputValue,
  handleInputChange,
  selectedUniversity,
  onUniversitySelectionChange,
}) {
  const options = [
    { label: "UK", value: "uk" },
    { label: "USA", value: "usa" },
    { label: "China", value: "china" },
    { label: "Australia", value: "australia" },
  ];

  return (
    <div className="w-10/12 max-h-screen  admin-font md:block hidden rounded-md bg-[#1660ab] h-full">
      <div className="flex flex-col items-center justify-between py-2 max-h-screen  h-full  text-white">
        <div className="w-11/12">
          <h2 className="text-lg font-semibold mb-2"> Name</h2>
          <div className="relative mt-2 w-full">
            <input
              id="myInput"
              type="text"
              name="myCountry"
              placeholder="Course / Degree"
              className="py-2 px-2 rounded-md w-full bg-transparent border-white border-2  "
              value={inputValue}
              onChange={handleInputChange}
            />
            <IconSearch className="absolute right-2 top-2" />
          </div>
        </div>

        <div className=" text-start w-11/12 font-medium ">
          <h2 className="text-lg font-semibold mb-2"> Type</h2>
          <input
            type="checkbox"
            id="type1"
            name="type1"
            value="Undergraduate"
            checked={types.includes("Undergraduate") ? true : false}
            onChange={handleType}
          />
          <label for="type1"> Undergraduate</label>
          <br />
          <br></br>
          <input
            type="checkbox"
            id="type2"
            name="type2"
            value="Post-Graduate"
            className="accent-[#fffff]"
            checked={types.includes("Post-Graduate") ? true : false}
            onChange={handleType}
          />
          <label for="type2"> Postgraduate</label>
          <br />
        </div>

        <div className=" text-start w-11/12 font-medium  ">
          <h2 className="text-lg font-semibold mb-2"> Country</h2>

          <Select
            onChange={onCountryChange}
            options={options}
            value={country}
            className=" placeholder:text-white border-2 rounded-md"
            styles={{
              indicatorsContainer: (baseStyles, state) => ({
                ...baseStyles,

                color: state.isFocused ? "white" : "white",
              }),
              menuList: (baseStyles, state) => ({
                ...baseStyles,

                color: state.isFocused ? "black" : "black",
              }),
              singleValue: (baseStyles, state) => ({
                ...baseStyles,

                color: state.isFocused ? "white" : "white",
              }),
              dropdownIndicator: (baseStyles, state) => ({
                ...baseStyles,

                color: state.isFocused ? "white" : "white",
              }),
              input: (baseStyles, state) => ({
                ...baseStyles,

                color: state.isFocused ? "white" : "white",
              }),

              valueContainer: (baseStyles, state) => ({
                ...baseStyles,

                color: state.isFocused ? "black" : "black",
              }),

              placeholder: (baseStyles, state) => ({
                ...baseStyles,

                color: state.isFocused ? "white" : "white",
              }),
              container: (baseStyles, state) => ({
                ...baseStyles,

                color: state.isFocused ? "white" : "white",
              }),

              control: (baseStyles, state) => ({
                ...baseStyles,
                borderColor: state.isFocused ? "white " : "white",
                backgroundColor: state.isFocused
                  ? "transparent"
                  : "transparent",
                color: state.isFocused ? "white" : "white",
              }),
            }}
          />
        </div>

        <div className="w-11/12 text-wrap">
          <h2 className="text-lg font-semibold mb-2"> University</h2>

          <Select
            onChange={onUniversitySelectionChange}
            options={universities}
            value={selectedUniversity}
            menuPosition="fixed"
            placeholder="Select University"
            className=" placeholder:text-white border-2 rounded-md"
            styles={{
              indicatorsContainer: (baseStyles, state) => ({
                ...baseStyles,

                color: state.isFocused ? "white" : "white",
              }),
              menuList: (baseStyles, state) => ({
                ...baseStyles,

                color: state.isFocused ? "black" : "black",
              }),
              singleValue: (baseStyles, state) => ({
                ...baseStyles,

                color: state.isFocused ? "white" : "white",
              }),
              dropdownIndicator: (baseStyles, state) => ({
                ...baseStyles,

                color: state.isFocused ? "white" : "white",
              }),
              input: (baseStyles, state) => ({
                ...baseStyles,

                color: state.isFocused ? "white" : "white",
              }),

              valueContainer: (baseStyles, state) => ({
                ...baseStyles,

                color: state.isFocused ? "black" : "black",
              }),

              placeholder: (baseStyles, state) => ({
                ...baseStyles,

                color: state.isFocused ? "white" : "white",
              }),
              container: (baseStyles, state) => ({
                ...baseStyles,

                color: state.isFocused ? "white" : "white",
              }),

              control: (baseStyles, state) => ({
                ...baseStyles,
                borderColor: state.isFocused ? "white " : "white",
                backgroundColor: state.isFocused
                  ? "transparent"
                  : "transparent",
                color: state.isFocused ? "white" : "white",
              }),
            }}
          />
        </div>

        <div
          className="w-11/12 mt-2 "
          onClick={() =>
            onFilteration(inputValue, types, country, selectedUniversity)
          }
        >
          <button className="w-full py-3 rounded-md font-semibold bg-[#fab723] ">
            Filter Selection
          </button>
        </div>
      </div>
    </div>
  );
}
