import React, { useState } from "react";

import { useOutsideClick } from "./UseOutsideClick";
import { useRef } from "react";

import {  IconLogout, IconSearch } from "@tabler/icons-react";

import Select from "react-select";

const FilterPanelMobile = ({
  isSidebarOpen,
  setIsSidebarOpen,
  initial_search,
  country,
  onFilteration,
  universities,
  getUniversities,
  handleType,
  onCountryChange,
  types,
  inputValue,
  handleInputChange,
  selectedUniversity,
  onUniversitySelectionChange,
}) => {

  const sidebarRef = useRef(null);
  useOutsideClick(sidebarRef, () => {
    // Close the dropdown when a click occurs outside the modal
    setIsSidebarOpen(false);
  });

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };


  const options = [
    { label: "UK", value: "uk" },
    { label: "USA", value: "usa" },
    { label: "China", value: "china" },
    { label: "Australia", value: "australia" },
  ];
  return (
    <div
      ref={sidebarRef}
      className={`fixed bg-[#1660ab] admin-font h-screen  text-white  ${
        isSidebarOpen ? "w-64 p-4 top-0  w-screen   " : "w-0 top-0 "
      } transition-all duration-300 ease-in-out md:hidden block `}
    >
      <button
        className={`text-white font-semibold py-4  mb-4  w-full rounded  ${
          isSidebarOpen ? "text-end px-2 flex justify-end" : "text-end px-4"
        }`}
        onClick={toggleSidebar}
      >
        {isSidebarOpen ? (
        
          <IconLogout />
        ) : (
          ""
        )}
      </button>

      <div
        className={`flex flex-col items-center justify-between py-2 h-[85vh] z-100 ${
          isSidebarOpen ? "block" : "hidden"
        }`}
      >
        <div className="w-11/12">
          <h2 className="text-lg font-semibold mb-2"> Name</h2>
          <div className="relative mt-2 w-full">
            <input
              id="myInput"
              type="text"
              name="myCountry"
              placeholder="Course / Degree"
              className="text-white py-2 px-2 rounded-md w-full text-black bg-transparent border-white border-2 placeholder:text-white "
              value={inputValue}
              onChange={handleInputChange}
            />
            <IconSearch className="absolute right-2 top-2" />
          </div>
        </div>

        <div className=" text-start w-11/12 font-medium ">
          <h2 className="text-lg font-semibold mb-2"> Type</h2>
          <input
            type="checkbox"
            id="type3"
            name="type3"
            value="Undergraduate"
            checked={types.includes("Undergraduate") ? true : false}
            onChange={handleType}
          />
          <label htmlFor="type3"> Undergraduate</label>
          <br />
          <br></br>
          <input
            type="checkbox"
            id="type4"
            name="type4"
            value="Post-Graduate"
            checked={types.includes("Post-Graduate") ? true : false}
            onChange={handleType}
          />
          <label htmlFor="type4"> Postgraduate</label>
          <br />
        </div>

        <div className=" text-start w-11/12 font-medium  z-[100%]">
          <h2 className="text-lg font-semibold mb-2"> Country</h2>

          <Select
            onChange={onCountryChange}
            options={options}
            value={country}
            className=" placeholder:text-white border-2 rounded-md"
            styles={{
              indicatorsContainer: (baseStyles, state) => ({
                ...baseStyles,

                color: state.isFocused ? "white" : "white",
              }),
              menuList: (baseStyles, state) => ({
                ...baseStyles,

                color: state.isFocused ? "black" : "black",
              }),
              singleValue: (baseStyles, state) => ({
                ...baseStyles,

                color: state.isFocused ? "white" : "white",
              }),
              dropdownIndicator: (baseStyles, state) => ({
                ...baseStyles,

                color: state.isFocused ? "white" : "white",
              }),
              input: (baseStyles, state) => ({
                ...baseStyles,

                color: state.isFocused ? "white" : "white",
              }),

              valueContainer: (baseStyles, state) => ({
                ...baseStyles,

                color: state.isFocused ? "black" : "black",
              }),

              placeholder: (baseStyles, state) => ({
                ...baseStyles,

                color: state.isFocused ? "white" : "white",
              }),
              container: (baseStyles, state) => ({
                ...baseStyles,

                color: state.isFocused ? "white" : "white",
              }),

              control: (baseStyles, state) => ({
                ...baseStyles,
                borderColor: state.isFocused ? "white " : "white",
                backgroundColor: state.isFocused
                  ? "transparent"
                  : "transparent",
                color: state.isFocused ? "white" : "white",
              }),
            }}
          />
        </div>

        <div className="w-11/12">
          <h2 className="text-lg font-semibold mb-2"> University</h2>

          <Select
            onChange={onUniversitySelectionChange}
            options={universities}
            value={selectedUniversity}
            placeholder="Select University"
            menuPosition="fixed"
            className=" placeholder:text-white border-2 rounded-md"
            styles={{
              indicatorsContainer: (baseStyles, state) => ({
                ...baseStyles,

                color: state.isFocused ? "white" : "white",
              }),
              menuList: (baseStyles, state) => ({
                ...baseStyles,

                color: state.isFocused ? "black" : "black",
              }),
              singleValue: (baseStyles, state) => ({
                ...baseStyles,

                color: state.isFocused ? "white" : "white",
              }),
              dropdownIndicator: (baseStyles, state) => ({
                ...baseStyles,

                color: state.isFocused ? "white" : "white",
              }),
              input: (baseStyles, state) => ({
                ...baseStyles,

                color: state.isFocused ? "white" : "white",
              }),

              valueContainer: (baseStyles, state) => ({
                ...baseStyles,

                color: state.isFocused ? "black" : "black",
              }),

              placeholder: (baseStyles, state) => ({
                ...baseStyles,

                color: state.isFocused ? "white" : "white",
              }),
              container: (baseStyles, state) => ({
                ...baseStyles,

                color: state.isFocused ? "white" : "white",
              }),

              control: (baseStyles, state) => ({
                ...baseStyles,
                borderColor: state.isFocused ? "white " : "white",
                backgroundColor: state.isFocused
                  ? "transparent"
                  : "transparent",
                color: state.isFocused ? "white" : "white",
              }),
            }}
          />
        </div>

        <div
          className="w-11/12 mt-2 "
          onClick={() =>
            onFilteration(inputValue, types, country, selectedUniversity, true)
          }
        >
          <button className="w-full py-3 rounded-md bg-[#fab723]">
            Filter Selection
          </button>
        </div>
      </div>
    </div>
  );
};

export default FilterPanelMobile;
